import React, {useState} from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/CustomerReportModal.module.scss';
import DownloadDropdown from '../Dropdown/DownloadDropdown';
import Dropdown from '../Dropdown/Dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import axios from 'axios';
import {useQuery} from 'react-query';
import Pagination from 'react-js-pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../assets/images/fidelityLoader.gif';


const API_ENDPOINT = process.env.REACT_APP_DASHBOARD_BASEURL;

const CustomerReportModal = ({
  setOpenModal,
  setSelectedCell,
  selectedCell,
}) => {
  const toggleModal = () => {
    setOpenModal(false);
    setSelectedCell(null);
  };

  const year = [
    {value: 2024},
    {value: 2023},
    {value: 2022},
    {value: 2021},
    {value: 2020},
    {value: 2019},
    {value: 2018},
    {value: 2017},
    {value: 2016},
    {value: 2015},
  ];

  const monthData = [
    {value: 'Jan', monthNumber: '01'},
    {value: 'Feb', monthNumber: '02'},
    {value: 'Mar', monthNumber: '03'},
    {value: 'Apr', monthNumber: '04'},
    {value: 'May', monthNumber: '05'},
    {value: 'Jun', monthNumber: '06'},
    {value: 'Jul', monthNumber: '07'},
    {value: 'Aug', monthNumber: '08'},
    {value: 'Sep', monthNumber: '09'},
    {value: 'Oct', monthNumber: '10'},
    {value: 'Nov', monthNumber: '11'},
    {value: 'Dec', monthNumber: '12'},
  ];

  const [selectedYear, setSelectedYear] = useState(year[0].value);

  const transactionReportDate = new Date();
  const transactionReportMonth = transactionReportDate.getMonth();
  const [transactionReportMonthNumber, setTransactionReportMonthNumber] =
    useState(transactionReportMonth);

  const transactionReportMonthName = monthData[transactionReportMonthNumber];
  const [selectedMonth, setSelectedMonth] = useState(
    transactionReportMonthName?.value,
  );

  const customerReportQueryMonth = monthData.filter(
    each => each.value === selectedMonth,
  );

  const fetchCustomerReport = async (year = '', month = '', membered = '') => {
    const params = {
      api: 'get_customer_transactions_report',
      year: year || undefined,
      month: month || undefined,
      memberid: membered,
    };
    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {Token: process.env.REACT_APP_FIDELITY_TOKEN},
    });

    return response?.data?.data;
  };

  const {data, isLoading, isError} = useQuery(
    ['Customer Report', selectedYear, customerReportQueryMonth],
    () =>
      fetchCustomerReport(
        selectedYear,
        customerReportQueryMonth[0].monthNumber,
        selectedCell?.value || '',
      ),
    {enabled: !!selectedCell},
  );

  // /////////////////////  PAGINATION HERE //////////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.data?.slice(indexOfFirstItem, indexOfLastItem);
  const cols = [
    {field: 'Transaction_date', header: 'Date'},
    {field: 'Ref', header: 'Ref'},
    {field: 'Transaction_amount', header: 'Amount'},
    {field: 'Rewards', header: 'Rewards'},
  ];

  console.log(data, 'data');

  return (
    <div>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.headerContainer}>
            <div className={styles.textContainer}>
              <h2>{selectedCell?.value}</h2>
              <p>View Customer Transation history.</p>
            </div>
            <div onClick={toggleModal}>
              <Times />
            </div>
          </div>
          <div className={styles.outteBalanceContainer}>
            <div className={styles.balanceContainer}>
              <div className={styles.container1}>
                <p>Available balance</p>
                <h6>{data?.balances ? data?.balances[0]?.Amount : '0'}</h6>
              </div>
              <div className={styles.container2}>
                <p>Total Points Received</p>
                <h6>{data?.balances ? data?.balances[1]?.Amount : '0'}</h6>
              </div>
              <div className={styles.container3}>
                <p>Total Blocked Points</p>
                <h6>{data?.balances ? data?.balances[2]?.Amount : '0'}</h6>
              </div>
              <div className={styles.container4}>
                <p>Total Points used</p>
                <h6>{data?.balances ? data?.balances[3]?.Amount : '0'}</h6>
              </div>
            </div>
          </div>
          <div className={styles.dropdownOutterCalendar}>
            <div className={styles.dropdownCalendar}>
              <Dropdown
                data={year}
                rangeValue={selectedYear}
                setRangeValue={setSelectedYear}
              />
              <Dropdown
                data={monthData}
                rangeValue={selectedMonth}
                setRangeValue={setSelectedMonth}
              />
            </div>
            <DownloadDropdown products={currentItems} cols={cols} />
          </div>
          <div>
            <div className={styles.tableContainer}>
              {isLoading ? (
                <div className={styles.loaderContainer}>
                  <img src={Loader} alt="loader" />
                </div>
              ) : (
                <DataTable
                  value={currentItems}
                  sortable
                  sortField="Transaction_date"
                  sortOrder={-1}
                  className={styles.mainTable}>
                  <Column
                    field="Transaction_date"
                    header="Date"
                    sortable
                    style={{width: '20%'}}></Column>
                  <Column
                    field="Ref"
                    header="Ref"
                    sortable
                    style={{width: '20%'}}></Column>
                  <Column
                    field="Transaction_amount"
                    header="Transaction Amount"
                    //   body={priceBodyTemplate}
                    sortable
                    style={{width: '20%'}}></Column>
                  <Column
                    field="Reward"
                    header="Earned Points"
                    //   body={priceBodyTemplate}
                    sortable
                    style={{width: '20%'}}></Column>
                </DataTable>
              )}
            </div>
            <div className={styles.paginationContainer}>
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={data?.data?.length}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReportModal;
