import React from 'react';

import FidelityStructure from '../FidelityStructure/FidelityStructure';
import styles from '../../styles/Dashboard.module.scss';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
} from 'recharts';
import {useState} from 'react';
import {useEffect} from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import Chart from 'react-google-charts';
import {useQuery} from 'react-query';
import axios from 'axios';
import Loader from '../../assets/images/fidelityLoader.gif';
import {LoadingIcon} from '../../assets/icons';
import {Link} from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_DASHBOARD_BASEURL;

const Dashboard = () => {
  const DropdownYear = [
    {value: 2024},
    {value: 2023},
    {value: 2022},
    {value: 2021},
    {value: 2020},
    {value: 2019},
    {value: 2018},
    {value: 2017},
    {value: 2016},
    {value: 2015},
  ];

  const [barChartStyling, setBarChartStyling] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      const value = window.innerWidth;
      if (value > 888) {
        setBarChartStyling(value - 300);
      } else {
        setBarChartStyling(value - 50);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const monthData = [
    {value: 'Jan', monthNumber: '01'},
    {value: 'Feb', monthNumber: '02'},
    {value: 'Mar', monthNumber: '03'},
    {value: 'Apr', monthNumber: '04'},
    {value: 'May', monthNumber: '05'},
    {value: 'Jun', monthNumber: '06'},
    {value: 'Jul', monthNumber: '07'},
    {value: 'Aug', monthNumber: '08'},
    {value: 'Sep', monthNumber: '09'},
    {value: 'Oct', monthNumber: '10'},
    {value: 'Nov', monthNumber: '11'},
    {value: 'Dec', monthNumber: '12'},
  ];

  ////////////// API CALL FOR Transaction Report/////////////////////////
  const [dataYear, setDataYear] = useState(DropdownYear[0]?.value);
  const [reportError, setReportError] = useState('');
  const fetchData = async (year = '') => {
    const params = {
      api: 'get_transaction_data',
      year: year || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    if (response?.data?.status === 0) {
      setReportError(response?.data?.message);
    }

    return response?.data.data;
  };

  const {
    data: transactionReport,
    isLoading,
    isError,
    refetch,
  } = useQuery(['transactionData', dataYear], () => fetchData(dataYear));

  const handleYearToggle = () => {
    setDataYear(dataYear);
    refetch();
  };

  useEffect(() => {
    handleYearToggle();
  }, [dataYear]);

  // get_channel_summary_data;

  /////////////////////// Channel Usage Summary (Pie Chart)//////////////////////////////

  const options = {
    // title: 'My Daily Activities',
    // tooltip: {trigger: 'selection'}, //To hide tooltip
    // legend: {position: 'none'}, Chart options name.
    pieHole: 0.5,
    is3D: false,

    colors: ['#0E237D', '#0D99FF', '#4FC143', '#27824D', '#989898'],
  };
  const [channelUssageYear, setChannelUssageYear] = useState(
    DropdownYear[0]?.value,
  );
  const [channelUssageError, setChannelUssageError] = useState('');

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  let previousMonth;

  if (currentMonth === 0) {
    previousMonth = 11;
  } else {
    previousMonth = currentMonth - 1;
  }

  const [channelUssageMonthNumber, setChannelUssageMonthNumber] =
    useState(previousMonth);

  const currentMonthName = monthData[channelUssageMonthNumber];

  const [channelUssageMonth, setChannelUssageMonth] = useState(
    currentMonthName?.value,
  );

  const queryMonth = monthData.filter(
    each => each.value === channelUssageMonth,
  );
  const fetchChannelUssageData = async (year = '', month = '') => {
    const params = {
      api: 'get_channel_summary_data',
      year: year || undefined,
      month: month || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    if (response.data.status === 0) {
      setChannelUssageError(response?.data.message);
    }

    return response?.data?.data?.[0]?.output;
  };

  const {
    data: channelUssageData,
    isLoading: isLoadingChannelData,
    isError: isErrorChannelData,
    refetch: refetchChannelData,
  } = useQuery(['channelUssageData', channelUssageYear, queryMonth], () =>
    fetchChannelUssageData(channelUssageYear, queryMonth[0]?.monthNumber),
  );

  //////////////////////// Customer Activity Summary////////////////////////////

  // const customerActivityOption = {

  //   pieHole: 0.5,
  //   is3D: false,
  //   colors: ['#0E237D', '#4FC143'],
  // };

  const [customerActivityYear, setCustomerActivityYear] = useState(
    DropdownYear[0]?.value,
  );

  const customerActivityCurrentDate = new Date();
  const customerActivityCurrentMonth = customerActivityCurrentDate.getMonth();
  let customerActivityCurrentPreviousMonth;

  if (customerActivityCurrentMonth === 0) {
    customerActivityCurrentPreviousMonth = 11;
  } else {
    customerActivityCurrentPreviousMonth = customerActivityCurrentMonth - 1;
  }

  const [customerActivityMonthNumber, setCustomerActivityMonthNumber] =
    useState(customerActivityCurrentPreviousMonth);

  const customerActivityMonthName = monthData[customerActivityMonthNumber];

  const [customerActivityMonth, setCustomerActivityMonth] = useState(
    customerActivityMonthName?.value,
  );

  const customerActivityQueryMonth = monthData.filter(
    each => each.value === customerActivityMonth,
  );

  // const fetchCustomerActivityData = async (year = '', month = '') => {
  //   const params = {
  //     api: 'get_customers_activities',
  //     year: year || undefined,
  //     month: month || undefined,
  //   };

  //   const response = await axios.get(API_ENDPOINT, {
  //     params,
  //     headers: {
  //       Token: process.env.REACT_APP_FIDELITY_TOKEN,
  //     },
  //   });

  //   return response?.data?.data[0];
  // };

  // const {
  //   data: customerActivityData,
  //   isLoading: isLoadingCustomerActivity,
  //   isError: isErrorCustomerActivity,
  //   refetch: refetchCustomerActivity,
  // } = useQuery(
  //   [
  //     'Customer Activity Data',
  //     customerActivityYear,
  //     customerActivityQueryMonth,
  //   ],
  //   () =>
  //     fetchCustomerActivityData(
  //       customerActivityYear,
  //       customerActivityQueryMonth[0]?.monthNumber,
  //     ),
  // );

  // console.log(customerActivityData, 'customerActivityData');

  /////////////////////////////////// Enrollment Report/////////////////////////////////////
  const [enrolmentReportYear, setEnrolmentReportYear] = useState(
    DropdownYear[0]?.value,
  );
  const fetchEnrolmentReportData = async (year = '') => {
    const params = {
      api: 'get_enrollment_data',
      year: year || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    return response?.data.data;
  };

  const {
    data: enrolmentReport,
    isLoading: isLoadingEnrolment,
    isError: isErrorEnrolment,
    refetch: refetchEnrolment,
  } = useQuery(['enrolmentData', enrolmentReportYear], () =>
    fetchEnrolmentReportData(enrolmentReportYear),
  );

  const handleEnrolmentYearToggle = () => {
    setEnrolmentReportYear(enrolmentReportYear);
    refetchEnrolment();
  };

  useEffect(() => {
    handleEnrolmentYearToggle();
  }, [enrolmentReportYear]);

  /////////////// Expired & Forfeited Points Chart //////////////////////////////
  const expiredPointOption = {
    pieHole: 0.5,
    is3D: false,
    colors: ['#747272', '#0D99FF'],
  };

  const [expiredPointYear, setExpiredPointYear] = useState(
    DropdownYear[0]?.value,
  );

  const expiredPointCurrentDate = new Date();
  const expiredPointCurrentMonth = expiredPointCurrentDate.getMonth();
  let expiredPointCurrentPreviousMonth;

  if (expiredPointCurrentMonth === 0) {
    expiredPointCurrentPreviousMonth = 11;
  } else {
    expiredPointCurrentPreviousMonth = expiredPointCurrentMonth - 1;
  }

  const [expiredPointMonthNumber, setExpiredPointMonthNumber] = useState(
    expiredPointCurrentPreviousMonth,
  );

  const expiredPointMonthName = monthData[expiredPointMonthNumber];

  const [expiredPointMonth, setExpiredPointMonth] = useState(
    expiredPointMonthName?.value,
  );

  const expiredPointQueryMonth = monthData.filter(
    each => each.value === expiredPointMonth,
  );

  const fetchExpiredPointData = async (year = '', month = '') => {
    const params = {
      api: 'get_expired_forfeited_points_report',
      year: year || undefined,
      month: month || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    // if (response.data.status === 0) {
    //   setChannelUssageError(response?.data.message);
    // }

    return response?.data?.data?.output;
  };

  const {
    data: expiredPointData,
    isLoading: isLoadingExpiredPoint,
    isError: isErrorExpiredPoint,
    refetch: refetchExpiredPoint,
  } = useQuery(
    ['Expired Point Data', expiredPointYear, expiredPointQueryMonth],
    () =>
      fetchExpiredPointData(
        expiredPointYear,
        expiredPointQueryMonth[0]?.monthNumber,
      ),
  );

  // ////////////////////////// Redemption Chart ///////////////////////////
  const RedemptionOptions = {
    tooltip: {trigger: 'none'},
    pieHole: 0.5,
    is3D: false,
    colors: ['#FF6B6A', '#FFCD29', '#7B61FF', '#E1E7FF', '#0D99FF'],
  };

  const [redemptionYear, setRedemptionYear] = useState(DropdownYear[0]?.value);

  const redemptionDate = new Date();
  const redemptionCurrentMonth = redemptionDate.getMonth();
  let redemptionPreviousMonth;

  if (redemptionCurrentMonth === 0) {
    redemptionPreviousMonth = 11;
  } else {
    redemptionPreviousMonth = redemptionCurrentMonth - 1;
  }

  const [redemptionMonthNumber, setRedemptionMonthNumber] = useState(
    customerActivityCurrentPreviousMonth,
  );

  const redemptionMonthName = monthData[redemptionMonthNumber];

  const [redemptionMonth, setRedemptionMonth] = useState(
    redemptionMonthName?.value,
  );

  const redemptionQueryMonth = monthData.filter(
    each => each.value === redemptionMonth,
  );

  const fetchRedemptionData = async (year = '', month = '') => {
    const params = {
      api: 'get_redemption_report',
      year: year || undefined,
      month: month || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    return response?.data?.data[0]?.output;
  };

  const {
    data: redemptionData,
    isLoading: isLoadingRedemption,
    isError: isErrorRedemption,
    refetch: refetchRedemption,
  } = useQuery(['Redemption Data', redemptionYear, redemptionQueryMonth], () =>
    fetchRedemptionData(redemptionYear, redemptionQueryMonth[0]?.monthNumber),
  );

  ///////////////////// Customer Delivery Chart////////////////////////////////
  const customerDeliveryOption = {
    pieHole: 0.5,
    is3D: false,
    colors: ['#4FC143', '#FF005F'],
  };

  const [customerDeliveryYear, setCustomerDeliveryYear] = useState(
    DropdownYear[0]?.value,
  );

  const customerDeliveryDate = new Date();
  const customerDeliveryCurrentMonth = customerDeliveryDate.getMonth();
  let customerDeliveryPreviousMonth;

  if (customerDeliveryCurrentMonth === 0) {
    customerDeliveryPreviousMonth = 11;
  } else {
    customerDeliveryPreviousMonth = customerDeliveryCurrentMonth - 1;
  }

  const [customerDeliveryMonthNumber, setCustomerDeliveryMonthNumber] =
    useState(customerActivityCurrentPreviousMonth);

  const customerDeliveryMonthName = monthData[customerDeliveryMonthNumber];

  const [customerDeliveryMonth, setCustomerDeliveryMonth] = useState(
    customerDeliveryMonthName?.value,
  );

  const customerDeliveryQueryMonth = monthData.filter(
    each => each.value === customerDeliveryMonth,
  );

  const fetchCustomerDelivery = async (year = '', month = '') => {
    const params = {
      api: 'get_customers_delivery_report',
      year: year || undefined,
      month: month || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    // if (response.data.status === 0) {
    //   setChannelUssageError(response?.data.message);
    // }

    return response?.data?.data?.output;
  };

  const {
    data: customerDeliveryData,
    isLoading: customerDeliveryLoading,
    isError: customerDeliveryError,
  } = useQuery(
    [
      'Customer Delivery Data',
      customerDeliveryYear,
      customerDeliveryQueryMonth,
    ],
    () =>
      fetchCustomerDelivery(
        customerDeliveryYear,
        customerDeliveryQueryMonth[0]?.monthNumber,
      ),
  );

  // console.log(customerDeliveryData, 'customerDeliveryData');

  // /////////////////////////Customer Dashboard///////////////////////////

  // const customerDashboardOptions = {

  //   pieHole: 0.5,
  //   is3D: false,
  //   colors: ['#0D99FF', '#989898', '#4FC143', '#27824D'],
  // };

  // const [customerDashboardYear, setCustomerDashboardYear] = useState(
  //   DropdownYear[0]?.value,
  // );

  // const customerDashboardDate = new Date();
  // const customerDashboardCurrentMonth = customerDashboardDate.getMonth();
  // let customerDashboardPreviousMonth;

  // if (customerDashboardCurrentMonth === 0) {
  //   customerDashboardPreviousMonth = 11;
  // } else {
  //   customerDashboardPreviousMonth = customerDashboardCurrentMonth - 1;
  // }

  // const [customerDashboardMonthNumber, setCustomerDashboardMonthNumber] =
  //   useState(customerActivityCurrentPreviousMonth);

  // const customerDashboardMonthName = monthData[customerDashboardMonthNumber];

  // const [customerDashboardMonth, setCustomerDashboardMonth] = useState(
  //   customerDashboardMonthName?.value,
  // );

  // const customerDashboardQueryMonth = monthData.filter(
  //   each => each.value === customerDashboardMonth,
  // );

  // const fetchCustomerDashboard = async (year = '', month = '') => {
  //   const params = {
  //     api: 'get_dashboard_report',
  //     year: year || undefined,
  //     month: month || undefined,
  //   };

  //   const response = await axios.get(API_ENDPOINT, {
  //     params,
  //     headers: {
  //       Token: process.env.REACT_APP_FIDELITY_TOKEN,
  //     },
  //   });

  //   return response?.data?.data;
  // };

  // const {
  //   data: customerDashboardData,
  //   isLoading: customerDashboardLoading,
  //   isError: customerDashboardError,
  // } = useQuery(
  //   [
  //     'Customer Dashboard Data',
  //     customerDashboardYear,
  //     customerDashboardQueryMonth,
  //   ],
  //   () =>
  //     fetchCustomerDashboard(
  //       customerDashboardYear,
  //       customerDashboardQueryMonth[0]?.monthNumber,
  //     ),
  // );

  // console.log(get_point_accumulation_data, 'get_point_accumulation_data');

  /////////////////////////////////// Point Accumulation Report/////////////////////////////////////
  const [pointAccumulationReportYear, setPointAccumulationReportYear] =
    useState(DropdownYear[0]?.value);

  const fetchPointAccumulationReportData = async (year = '') => {
    const params = {
      api: 'get_point_accumulation_data',
      year: year || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });

    return response?.data.data;
  };

  const {
    data: pointAccumulationReport,
    isLoading: isLoadingPointAccumulation,
    isError: isErrorPointAccumulation,
    refetch: refetchPointAccumulation,
  } = useQuery(['Point Accumulation Data', pointAccumulationReportYear], () =>
    fetchPointAccumulationReportData(pointAccumulationReportYear),
  );

  const handlePointAccumulationYearToggle = () => {
    setPointAccumulationReportYear(pointAccumulationReportYear);
    refetchPointAccumulation();
  };

  useEffect(() => {
    handlePointAccumulationYearToggle();
  }, [pointAccumulationReportYear]);

  // const handleYearToggle = () => {
  //   setDataYear(dataYear);
  //   refetch();
  // };

  // useEffect(() => {
  //   handleYearToggle();
  // }, [dataYear]);

  // console.log(pointAccumulationReport, 'pointAccumulationReport');

  ////////////// POINT SUMMARY API AND CUSTOMER ACTIVITY SUMMARY API///////////

  const fetchPointSummary = async () => {
    const params = {
      api: 'get_point_summary_data',
    };
    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });
    return response?.data?.data?.cards;
  };

  const {
    data: pointSummaryData,
    isLoading: isLoadingPointSummary,
    refetch: refetchPointSummary,
  } = useQuery('Get Point Summary', () => fetchPointSummary());

  const fetchCustomerActivity = async () => {
    const params = {
      api: 'get_customers_activities',
    };
    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {Token: process.env.REACT_APP_FIDELITY_TOKEN},
    });
    return response?.data?.data?.cards;
  };

  const {
    data: customerActivityData,
    isLoading: isLoadingCustomerActivity,
    refetch: refetchCustomerActivity,
  } = useQuery('Customer Activity Summary', () => fetchCustomerActivity());

  // const fetchRedemptionReport = async () => {
  //   const params = {
  //     api: 'get_redemption_report',
  //   };
  //   const response = await axios.get(API_ENDPOINT, {
  //     params,
  //     headers: {
  //       Token: process.env.REACT_APP_FIDELITY_TOKEN,
  //     },
  //   });
  // };

  // const {data: redemptionData, }

  ///////////////////////////// END//////////////////////////////////////////////

  console.log(transactionReport, 'transactionReport');

  return (
    <FidelityStructure>
      <div>
        <div className={styles.topCard}>
          <div className={styles.leftCardContainer}>
            <div className={styles.barChartTop}>
              <h4>Customer Activity Summary</h4>
              <p>View all customer activity on the platform</p>
            </div>
            <div className={styles.cardContainer}>
              <div className={styles.CASCard1}>
                <p>Logged In Customers</p>
                <h4>
                  {isLoadingCustomerActivity ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(customerActivityData?.[0]?.count).toLocaleString()
                  )}
                </h4>
              </div>
              <div className={styles.CASCard2}>
                <p>Customers not logged In</p>
                <h4>
                  {isLoadingCustomerActivity ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(customerActivityData?.[1]?.count).toLocaleString()
                  )}
                </h4>
              </div>
              <div className={styles.CASCard3}>
                <p>Customers who redeemed</p>
                <h4>
                  {isLoadingCustomerActivity ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(customerActivityData?.[2]?.count).toLocaleString()
                  )}
                </h4>
              </div>
              <div className={styles.CASCard4}>
                <p>Customers yet to redeem</p>
                <h4>
                  {isLoadingCustomerActivity ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(customerActivityData?.[3]?.count).toLocaleString()
                  )}
                </h4>
              </div>
            </div>
          </div>
          <div className={styles.rightCardContainer}>
            <div className={styles.barChartTop}>
              <h4>Points Summary</h4>
              <p>View total point summary</p>
            </div>
            <div className={styles.cardContainer}>
              <div className={styles.CASCard5}>
                <p>Total points Redeemed</p>
                <h4>
                  {isLoadingPointSummary ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(pointSummaryData?.[1]?.point).toLocaleString()
                  )}
                </h4>
              </div>
              <div className={styles.CASCard6}>
                <p>Total Points Earned</p>
                <h4>
                  {isLoadingPointSummary ? (
                    <LoadingIcon />
                  ) : (
                    parseInt(pointSummaryData?.[0]?.point).toLocaleString()
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.barChartContainer}>
          <div className={styles.barChartTopContainer}>
            <div className={styles.barChartTop}>
              <h4>Incremental Income Report</h4>
              <p>View all Incremental Income made by customers</p>
            </div>
            <div className={styles.incrementalReportContainer}>
              <Link
                target="_blank"
                to="https://perx3fidelity.com/monthly_transaction_report.php">
                <button className={styles.incrementalBTN}>
                  Download Report
                </button>
              </Link>
              <Dropdown
                data={DropdownYear}
                rangeValue={dataYear}
                setRangeValue={setDataYear}
              />
            </div>
          </div>
          {/* {!isLoading && (
            <div className={styles.errorMessageStyling}>{reportError}</div>
          )} */}
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <img src={Loader} alt="loader" />
            </div>
          ) : transactionReport != undefined ? (
            <BarChart
              width={barChartStyling}
              height={250}
              margin={{top: 5, right: 10, left: 20, bottom: 5}}
              data={transactionReport}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              {/* <Legend /> */}
              <Bar dataKey="amount" fill="#4FC143" />
            </BarChart>
          ) : (
            <div className={styles.nodataContainer}>
              <h1>No Data</h1>
            </div>
          )}
        </div>
        <div className={styles.barChartContainer}>
          <div className={styles.barChartTopContainer}>
            <div className={styles.barChartTop}>
              <h4>Points Accumulation Report</h4>
              <p>View all points earned by customers</p>
            </div>
            <Dropdown
              data={DropdownYear}
              rangeValue={pointAccumulationReportYear}
              setRangeValue={setPointAccumulationReportYear}
            />
          </div>
          {/* {!isLoading && (
            <div className={styles.errorMessageStyling}>{reportError}</div>
          )} */}
          {isLoadingPointAccumulation ? (
            <div className={styles.loaderContainer}>
              <img src={Loader} alt="loader" />
            </div>
          ) : pointAccumulationReport !== undefined ? (
            <BarChart
              width={barChartStyling}
              height={250}
              margin={{top: 5, right: 10, left: 20, bottom: 5}}
              data={pointAccumulationReport}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              {/* <Legend /> */}
              <Bar dataKey="Point" fill="#FFC400" />
            </BarChart>
          ) : (
            <div className={styles.nodataContainer}>
              <h1>No Data</h1>
            </div>
          )}
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieContainer}>
            <div>
              <div className={styles.barChartTop}>
                <h4>Channel Usage Summary</h4>
                <p>This is a summary of all the channel usage</p>
              </div>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  data={DropdownYear}
                  rangeValue={channelUssageYear}
                  setRangeValue={setChannelUssageYear}
                />
                <Dropdown
                  data={monthData}
                  rangeValue={channelUssageMonth}
                  setRangeValue={setChannelUssageMonth}
                />
              </div>
            </div>

            {isLoadingChannelData ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : channelUssageData?.amount ? (
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={channelUssageData?.amount}
                options={options}
              />
            ) : (
              <div className={styles.nodataContainer}>
                <h1>No Data</h1>
              </div>
            )}
            <div className={styles.usageCountContainer}>
              <h5 className={styles.usuageCountText}>Usage Count</h5>
              <div className={styles.usageCountInnerContainer}>
                <div className={styles.usuageCount1}>
                  <h5 className={styles.usuageTitle}>Mobile</h5>
                  <p className={styles.usuageNumber}>
                    {channelUssageData?.usage_count?.[1]?.usage_count
                      ? parseInt(
                          channelUssageData?.usage_count?.[1]?.usage_count,
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount2}>
                  <h5 className={styles.usuageTitle}>ATM</h5>
                  <p className={styles.usuageNumber}>
                    {channelUssageData?.usage_count?.[0]?.usage_count
                      ? parseInt(
                          channelUssageData?.usage_count?.[0]?.usage_count,
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount3}>
                  <h5 className={styles.usuageTitle}>Web</h5>
                  <p className={styles.usuageNumber}>
                    {channelUssageData?.usage_count?.[4]?.usage_count
                      ? parseInt(
                          channelUssageData?.usage_count?.[4]?.usage_count,
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount4}>
                  <h5 className={styles.usuageTitle}>POS</h5>
                  <p className={styles.usuageNumber}>
                    {channelUssageData?.usage_count?.[2]?.usage_count
                      ? parseInt(
                          channelUssageData?.usage_count?.[2]?.usage_count,
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount5}>
                  <h5 className={styles.usuageTitle}>USSD</h5>
                  <p className={styles.usuageNumber}>
                    {channelUssageData?.usage_count?.[3]?.usage_count
                      ? parseInt(
                          channelUssageData?.usage_count?.[3]?.usage_count,
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pieContainer}>
            <div>
              <div className={styles.barChartTop}>
                <h4>Redemption Chart</h4>
                <p>View all redemption chart here</p>
              </div>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  data={DropdownYear}
                  rangeValue={redemptionYear}
                  setRangeValue={setRedemptionYear}
                />
                <Dropdown
                  data={monthData}
                  rangeValue={redemptionMonth}
                  setRangeValue={setRedemptionMonth}
                />
              </div>
              {/* <div className={styles.dateContainer}>
                <div className={styles.innerDateContainer}>
                  <label htmlFor="">From</label>
                  <input type="date" name="From" id="" />
                </div>
                <div className={styles.innerDateContainer}>
                  <label htmlFor="">To</label>
                  <input type="date" name="From" id="" />
                </div>
              </div> */}
            </div>

            {isLoadingRedemption ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : redemptionData?.amount ? (
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={redemptionData?.amount}
                options={RedemptionOptions}
              />
            ) : (
              <div className={styles.nodataContainer}>
                <h1>No Data</h1>
              </div>
            )}
            {/* <div className={styles.usageCountContainer}>
              <h5 className={styles.usuageCountText}>Usage Count</h5>
              <div className={styles.usageCountInnerContainer}>
                <div className={styles.usuageCount1Redemp}>
                  <h5 className={styles.usuageTitle}>Merchandize</h5>
                  <p className={styles.usuageNumber}>
                    {redemptionData?.usage_count?.[0]
                      ? parseInt(
                          redemptionData?.usage_count?.[0],
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount2Redemp}>
                  <h5 className={styles.usuageTitle}>Airtime & Bills</h5>
                  <p className={styles.usuageNumber}>
                    {redemptionData?.usage_count?.[1]
                      ? parseInt(
                          redemptionData?.usage_count?.[1],
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount3Redemp}>
                  <h5 className={styles.usuageTitle}>Meals</h5>
                  <p className={styles.usuageNumber}>
                    {redemptionData?.usage_count?.[2]
                      ? parseInt(
                          redemptionData?.usage_count?.[2],
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount4Redemp}>
                  <h5 className={styles.usuageTitle}>Entertainment</h5>
                  <p className={styles.usuageNumber}>
                    {redemptionData?.usage_count?.[3]
                      ? parseInt(
                          redemptionData?.usage_count?.[3],
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
                <div className={styles.usuageCount5Redemp}>
                  <h5 className={styles.usuageTitle}>Vouchers</h5>
                  <p className={styles.usuageNumber}>
                    {redemptionData?.usage_count?.[4]
                      ? parseInt(
                          redemptionData?.usage_count?.[4],
                          10,
                        )?.toLocaleString()
                      : '0'}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.barChartContainer}>
          <div className={styles.barChartTopContainer}>
            <div className={styles.barChartTop}>
              <h4>Enrollment Report</h4>
              <p>This a graph that shows all the enrollment done</p>
            </div>
            <Dropdown
              data={DropdownYear}
              rangeValue={enrolmentReportYear}
              setRangeValue={setEnrolmentReportYear}
            />
          </div>

          {isLoadingEnrolment ? (
            <div className={styles.loaderContainer}>
              <img src={Loader} alt="loader" />
            </div>
          ) : (
            <BarChart
              width={barChartStyling}
              margin={{top: 5, right: 10, left: 20, bottom: 5}}
              height={250}
              data={enrolmentReport}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              <Bar dataKey="amount" fill="#0E237D" />
            </BarChart>
          )}
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieContainer}>
            <div>
              <div className={styles.barChartTop}>
                <h4>Expired & Forfeited Points Chart</h4>
                <p>View Expired & Forfeited Points Chart here</p>
              </div>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  data={DropdownYear}
                  rangeValue={expiredPointYear}
                  setRangeValue={setExpiredPointYear}
                />
                <Dropdown
                  data={monthData}
                  rangeValue={expiredPointMonth}
                  setRangeValue={setExpiredPointMonth}
                />
              </div>
            </div>

            {isLoadingExpiredPoint ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : expiredPointData ? (
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={expiredPointData}
                options={expiredPointOption}
              />
            ) : (
              <div className={styles.nodataContainer}>
                <h1>No Data</h1>
              </div>
            )}
          </div>
          <div className={styles.pieContainer}>
            <div>
              <div className={styles.barChartTop}>
                <h4>Customer Delivery Chart</h4>
                <p>This is a summary of all the delivery status</p>
              </div>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  data={DropdownYear}
                  rangeValue={customerDeliveryYear}
                  setRangeValue={setCustomerDeliveryYear}
                />
                <Dropdown
                  data={monthData}
                  rangeValue={customerDeliveryMonth}
                  setRangeValue={setCustomerDeliveryMonth}
                />
              </div>
            </div>

            {customerDeliveryLoading ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : customerDeliveryData ? (
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={customerDeliveryData}
                options={customerDeliveryOption}
              />
            ) : (
              <div className={styles.nodataContainer}>
                <h1>No Data</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </FidelityStructure>
  );
};

export default Dashboard;
